import { createStore } from 'vuex'

export default createStore({
  state: {
    name:'pcHome1'
  },
  getters: {
  },
  mutations: {
    change(state,name){
      state.name=name
    }
  },
  actions: {
  },
  modules: {
  }
})
