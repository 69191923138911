import { createRouter, createWebHashHistory } from 'vue-router'
import Home from '@/views/home.vue'

const routes = [
  {
    path: '/:pathMatch(.*)',
    //访问不存在路由时重定向首页或者404页面
    redirect: '/',
  },
  {
    path: '/',
    name: 'home',
    component:Home,
    children:[
      {
        path:'pcHome',
        name:"pcHome",
        component:()=>import('@/views/pc'),
        children:[
          {
            path:'Home',
            name:"pcHome1",
            component:()=>import('@/views/pc/content/Home')
          },
          {
            path:'aboutAja',
            name:"pcaboutAja",
            component:()=>import('@/views/pc/content/aboutAja')
          },
          {
            path:'curriculums',
            name:"pccurriculums",
            component:()=>import('@/views/pc/content/curriculums')
          },
          {
            path:'activities',
            name:"pcactivities",
            component:()=>import('@/views/pc/content/activities')
          },     
          {
            path:'enrichment',
            name:"pcenrichment",
            component:()=>import('@/views/pc/content/enrichment')
          },
          {
            path:'employment',
            name:"pcemployment",
            component:()=>import('@/views/pc/content/employment')
          },
        ]
      },
      {
        path:'mobileHome',
        name:"mobileHome",
        component:()=>import('@/views/mobile'),
        children:[
          {
            path:'Home',
            name:"mobileHome1",
            component:()=>import('@/views/mobile/content/Home')
          },
          {
            path:'aboutAja',
            name:"mobileaboutAja",
            component:()=>import('@/views/mobile/content/aboutAja')
          },
          {
            path:'curriculums',
            name:"mobilecurriculums",
            component:()=>import('@/views/mobile/content/curriculums')
          },
          {
            path:'activities',
            name:"mobileactivities",
            component:()=>import('@/views/mobile/content/activities')
          },     
          {
            path:'enrichment',
            name:"mobileenrichment",
            component:()=>import('@/views/mobile/content/enrichment')
          },
          {
            path:'employment',
            name:"mobileemployment",
            component:()=>import('@/views/mobile/content/employment')
          },
        ]
      },
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})
let status = true
router.beforeEach((to, from, next) => {//to表示将要进入的路由信息，from表示从哪里进入下一步的路由信息，每一次next都会调用全局守卫
  if(status){
    if(/(iPhone|iPad|iPod|iOS|Android)/i.test(navigator.userAgent)){
      next({path:'/mobileHome/Home'})
    }else{
      next({path:'/pcHome/Home'})
    }
    status = false
  }else{
    next()
  }
});

export default router
