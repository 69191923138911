<template>
  <div class="home">
    <router-view/>
  </div>
</template>

<script>
import { useRouter } from "vue-router";
export default {
  name: 'HomeView',
  setup(props) {
    const router = useRouter()
    
  },
}
</script>

<style lang='scss'>
  .home{
    width: 100%;
  }
</style>
