import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
const app = createApp(App)

import ElementPlus from 'element-plus'
import locale from 'element-plus/lib/locale/lang/zh-cn';
import 'element-plus/dist/index.css'
import * as ElIcons from '@element-plus/icons-vue'
for (const name in ElIcons){
	app.component(name,(ElIcons)[name])
}
app.use(ElementPlus,{locale})
app.use(ElementPlus)


app.use(store).use(router).mount('#app')
